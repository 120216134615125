import React from "react";
import { Provider, useSelector } from "react-redux";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import auth0 from "auth0-js";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { useLocation } from "@reach/router";
import popinReducer from "./state/popins";
import sfResidencesReducer from "./state/salesforceResidences";
import settingsReducer, { setAccessToken } from "./state/settings";
import { parseStringToBoolean } from "./utils/mixins";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";

const webAuth = new auth0.WebAuth({
  domain: process.env.GATSBY_AUTH0_DOMAIN ?? "uat-login.hife-coliving.com",
  clientID:
    process.env.GATSBY_AUTH0_CLIENTID ?? "X7Mc3c55xrzXaHkN54QvhwAHwONYUcuZ",
  redirectUri: process.env.GATSBY_AUTH0_REDIRECTURI,
  responseType: "token",
});

const rootReducer = combineReducers({
  settings: settingsReducer,
  popins: popinReducer,
  sfResidences: sfResidencesReducer,
});

export function isTokenExpired(token) {
  const currentTime = Math.floor(Date.now() / 1000);
  var decoded = jwt_decode(token);
  return decoded?.exp < currentTime;
}

const WrappedProvider = ({ element }) => {
  const accessToken = Cookies.get("accessToken");
  const currentResidence = Cookies.get("currentResidence");
  const isColiver = Cookies.get("isColiver");
  const idMews = Cookies.get("idMews");
  const accessToSlack = Cookies.get("accessToSlack");

  const store = configureStore({
    reducer: rootReducer,
    preloadedState: {
      settings: {
        residence: currentResidence ? JSON.parse(currentResidence) : null,
        idMews: idMews != undefined ? idMews : null,
        accessToken: accessToken,
        isColifer: parseStringToBoolean(isColiver),
        accessToSlack: parseStringToBoolean(accessToSlack),
        colifersOnlyLinkClicked: [],
      },
      popins: {
        popinList: [],
      },
      sfResidences: {
        residences: [],
      },
    },
  });

  if (accessToken) {
    if (isTokenExpired(accessToken)) {
      Cookies.remove("accessToken");
      Cookies.remove("idMews");
      Cookies.remove("menuItem");
      Cookies.remove("currentResidence");
      webAuth.logout({
        returnTo: process.env.GATSBY_AUTH0_REDIRECTURI,
      });
      store.dispatch(setAccessToken(""));
    }
  }

  if (!currentResidence) {
    if (typeof window !== "undefined" && window.location.pathname !== "/") {
      // window.location.href = "/";
    }
  }

  return (
    <Provider store={store}>
      <Helmet>
        {/* Add Google Tag Manager code to the <head> section */}
        <script>
          {`
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-PBJGVNWQ');
              `}
        </script>

        {/* Add Google Tag Manager noscript code to the <body> section */}
        <noscript>
          {`
                <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PBJGVNWQ" height="0" width="0" style="display:none;visibility:hidden"></iframe>
              `}
        </noscript>
      </Helmet>

      {element}
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        className="custom-toast"
      />
    </Provider>
  );
};

export default WrappedProvider;
